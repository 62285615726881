import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {FloatLabelType} from '@angular/material/form-field';

@Component({
  selector: 'design-input-select',
  templateUrl: './design-input-select.component.html',
  styleUrls: ['./design-input-select.component.scss']
})
export class DesignInputSelectComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  name: string;

  @Input()
  form: FormGroup;

  @Input()
  multiple: boolean;

  @Input()
  additionalInformation: string[];

  @Input()
  options: { value: any, label: string, disabled?: boolean, translate?: boolean, valueString?: string }[];

  @Input()
  customClasses = '';

  @Input()
  placeholder: string = '';

  @Input()
  floatLabel: FloatLabelType = 'auto';

  @Output()
  change = new EventEmitter<MatSelectChange>();

  constructor() {
  }

  ngOnInit() {
  }

  clear() {
    this.form.get(this.name).reset();
  }

  close(select: MatSelect) {
    select.close();
  }

}
