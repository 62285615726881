import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {Socket} from "ngx-socket-io";
import {environment} from "@env/environment";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket = new Socket({
        url: environment.domain.replace('/api', ''),
        options: {
          transports: ['websocket']
        },
      });
    }
  }

  getSocket() {
    if (this.socket) {
      return this.socket;
    }
    return null;
  }

  emit(_eventName: string, args: any) {
    if (this.socket) {
      this.socket.emit(_eventName, args);
    }
  }

  fromEvent<T>(eventName: string) {
    if (this.socket) {
      return this.socket.fromEvent<T>(eventName);
    }
  }
}
