<mat-form-field [formGroup]="form" class="w-100" [ngClass]="customClasses" [floatLabel]="floatLabel">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-select formControlName="{{name}}" [multiple]="multiple" #select (selectionChange)="change.emit($event)"
              [placeholder]="placeholder">
    <div *ngIf="multiple" class="my-1 col-12 d-flex justify-content-end">
      <button mat-button color="warn" (click)="clear()">{{'design.design-input-select:Избриши' | translate}}</button>
      <button mat-button color="warn" (click)="close(select)">{{'design.design-input-select:Затвори' | translate}}</button>
    </div>
    <mat-option class='{{additionalInformation && additionalInformation.indexOf(option.valueString) >= 0 && matOption.selected ? "active" : ""}}'
                #matOption
                *ngFor="let option of options"
                [value]="option.value"
                [disabled]="option.disabled">
      {{option.translate ? (option.label | translate) : option.label}}
      <p *ngIf="additionalInformation && additionalInformation.indexOf(option.valueString) >= 0 && matOption.selected"
         (keydown)="$event.stopPropagation()"
         (click)="$event.stopPropagation()">
      <mat-form-field>
        <textarea matInput
                  class="nested-input"
                  id="{{option.valueString}}"
                  type="text"
                  formControlName="{{option.valueString === 'Сопственички удел > 25%' ? 'additionalInfoShares' : 'additionalInfoOwnCompany'}}"
                  placeholder="{{'design.design-input-select:Дополнителни информации' | translate}}">
        </textarea>
      </mat-form-field>
      </p>
    </mat-option>
  </mat-select>
<!--  <mat-error *ngIf="form.controls[name].hasError('required')">-->
<!--    {{'design.design-input-select:{label} e' | translate:{label: label} }} <strong>{{'design.design-input-select:задолжително' | translate}}</strong>-->
<!--  </mat-error>-->
  <ng-content></ng-content>
</mat-form-field>
